"use client"
import "./globals.css";
import Head from "./head";
import { SessionProvider } from "next-auth/react";
import { SpeedInsights } from "@vercel/speed-insights/next"
import { Analytics } from '@vercel/analytics/react';


export default function RootLayout({ children }) {
  return (
    <html lang="en">
      <Head/>
      <body className="bg-black font-campton-light">
      <SessionProvider>
        {children}
        <SpeedInsights />
        <Analytics />
      </SessionProvider>
      </body>
    </html>
  );
}
